<template>
  <div class="page" v-if="need_invite_code">
		<div class="page_head">
			<img class="logo" src="@/assets/personal/t_logo-01.png" alt="">
			<span class="page-title">输入邀请码</span>
		</div>
    <div class="page_body">
      <div class="input_zone mt100">
        <van-cell-group>
          <van-field
            v-validate="'required'"
            v-model="celInviteCode"
            name="celInviteCode"
            type="text"
            :error-message="errors.first('celInviteCode')"
            placeholder="请输入邀请码"
          />
        </van-cell-group>
      </div>

      <div class="mt50 ml20 mr20">
        <PinkBtn text="确定" @click="validate"/>
      </div>
			<div class="con">
				<span @click="_ingoreClick">跳过</span>
			</div>
    </div>

    <div class="copyright tc">&copy; 淘街坊 - 发现身边优惠</div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import PinkBtn from "@/components/PinkBtn";
import CaptchaBtn from "@/components/CaptchaBtn";
import { Field, Button, CellGroup } from "vant";
import { wxBindPhone } from "@/api/user";
import ls from "@/libs/localstore";
import {checkInviteCode, ignoreCode} from '@/api/invite'

Vue.use(Field)
  .use(Button)
  .use(CellGroup);

require("@/libs/validate.js");

export default {
  name: "BindPhone",
  components: {
    StatusBar,
    NavBar,
    PinkBtn,
    CaptchaBtn
  },
  data() {
    return {
			need_invite_code: false,
      img: {
        close_btn: require("@/assets/common/close_btn.png")
      },
      celInviteCode: null,
      captcha: null
    };
  },
  mounted: function() {
		this._checkInviteCode()
	},
  methods: {
		_checkInviteCode() {
			let invite_code = localStorage.getItem('invite-code');
			if(!!invite_code) {
				this.celInviteCode = invite_code
				this.bind()
				localStorage.setItem('invite-code', '')
			}else{
				this.need_invite_code = true;
			}
		},
		_ingoreClick() {
			let params = {
				token: ls.getTmpToken()
			}
			ignoreCode(params).then(res => {
				if(res.errCode == 0) {
					this.$toast.success("操作成功")
					this.loginSuccessCallback()
				}else{
					this.$toast(res.errMsg);
				}
			})
			.catch(err => {
				this.$toast(err.errMsg);
			})
		},
    validate: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.bind();
        } else {
          // this.$toast("请按提示正确填写所有信息");
        }
      });
    },
    bind: function() {
      let params = {
        token: ls.getTmpToken(),
				code: this.celInviteCode
      };

      checkInviteCode(params)
        .then(res => {
          if (res.errCode == 0) {
            // this.$toast.success("操作成功");
						this.loginSuccessCallback()
          } else {
            this.$toast(res.errMsg);
          }
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
		},
		loginSuccessCallback: function() {
			let token = ls.getTmpToken()
			this.$cookie.set("user-token", token, { expires: "7d" });
      // 更新token
			ls.setToken(token);

      // 获取登录前的页面
      let url = this.$cookie.get("beforeLoginUrl");
      // 若是url不存在或者是授权页面则跳到首页
      if (!url || url.indexOf("/login") != -1) {
        this.$router.push("/");
      } else {
        // 清空这个cookie
        this.$cookie.set("beforeLoginUrl", "");
        // 跳转至开始的目标页面
        this.$router.push(url);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.page {
  background: #fff;
}

.page_head {
	padding: 30px 30px 0;
	display: flex;
	align-items: center;
}
.logo {
	width: 30px;
	height: 30px;
	border-radius: 30px;
	overflow: hidden;
	margin-right: 15px;
}

.page-title {
	font-size: 25px;
}


.input_zone {
  padding: 0 30px;
}
.findpwd {
  color: #4a4a4a;
}
.info_zone {
  font-size: 16px;
  color: #4a4a4a;
}
.info_zone a {
  color: #4a4a4a;
}
.van-cell {
  font-size: 16px;
}
[class*="van-hairline"]::after {
  border-top-color: transparent !important;
}

.con {
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.con span {
	font-size: 16px;
	color: #888;
}
</style>
